<template>
  <div>
    <Navbar />
    <router-view/>
  </div>
</template>

<script>
import Navbar from '@/components/navbar.vue';

export default {
  data (){
  },
  components : {
    Navbar
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

body{
  font-family: 'Noto Sans', sans-serif;
  margin: 0;
  max-width: 100%;
  color: #2c3e50;
  text-align: center;
  background: #f4f6f8;
}
button {
  font-family: 'Noto Sans', sans-serif;
}
</style>
