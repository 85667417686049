const baseURL = process.env.VUE_APP_API_BASE_URL;
const headers = {
  'Content-Type': 'application/json'
}

class apiCalls {
  async get(path) {
    return fetch( baseURL + path, { headers: headers } )
      .then(res => {
        if (res.ok){
          return res.json();
        } else {
          throw res.status;
        }
      })
  }
  async post(path, body) {
    return fetch( baseURL + path, { headers: headers, method: 'POST', body: body })
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw res.status;
        }
      })
  }
}

export default new apiCalls();
