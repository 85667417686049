<template>
  <div>
    <div id="nav">
      <div class="menuButtonContainer">
        <Button class="menuButton" icon="pi pi-bars" @click="openedSidebar = true" />
      </div>
      <div class="headerContainer">
        <router-link to="/">
          <h2>Polowanie na kody</h2>
          <h4>Remcon 2022</h4>
        </router-link>
      </div>
      <div class="menuButtonContainer">
        <Button @click="$store.dispatch('syncStatus')" class="menuButton" icon="pi pi-refresh" />
      </div>
    </div>
    <div class="sidebarContainer">
      <Sidebar v-model:visible="openedSidebar">
        <div class="menuItems">
          <h1>Menu</h1>
          <button @click="sidebarGoTo('/')">Strona główna</button>
          <button @click="sidebarGoTo('/profile')">Twój profil</button>
          <button @click="sidebarGoTo('/info')">Jak grać?</button>
          <button @click="sidebarGoTo('/contact')">Kontakt</button>
        </div>
      </Sidebar>
    </div>
      <div v-if="errorStatus.stopWebsite != undefined">
        <Dialog header="Gra nieaktywna" v-model:visible="errorStatus.stopWebsite" :closable="false" :modal="true">
          <h3>Gra jest nieakywna z następującego powodu:</h3>
          <p>{{ errorStatus.adminMessage }}</p>
        </Dialog>
        </div>
      <Dialog header="Zagrajmy w grę!" v-model:visible="$store.state.tutorialPrompt" :closable="false" :modal="true">
        <p>Po całym terenie konwentu rozłożone są kody, zbierz trzy by zdobyć szanse zalosowania po nagrodę.</p>
        <template #footer>
          <Button id="rejectButton" label="Szczegóły" @click="howToReject" />
          <Button label="Kontynuuj" @click="howToContinue" autofocus/>
        </template>
      </Dialog>
      <Dialog header="Stwórz swój profil" v-model:visible="$store.state.changeIDPrompt" :closable="false" ref="profileCreation" :modal="true">
        <p>Aby odebrać nagrody potrzebujemy twojego indentyfikatora konwentowego. Znajdziesz go na plakietce, którą dostałeś/aś na wejściu.</p>
        <p>Wpisz jedynie cztery cyfry, pomijając przedrostek.</p>
        <div class="textInputContainer">
          <InputText type="text" v-model="userID" placeholder="Twój ID" aria-describedby="username2-help" />
          <Button @click="submitUserID" style="margin: 0;"><i class="pi pi-angle-right" /></Button>
        </div>
        <h4 id="username2-help" class="p-error" v-if="userID.length != 4 && userIDSubmitTry == true">Twój ID powinien mieć 4 cyfry. Pamiętaj, żeby pominąć litery z przodu!</h4>
        <div class="textInputContainer bottomButtonContainer">
          <Button @click="NoIDHandlerPrompt = true">Na moim indentyfikatorze nie ma kodu!</Button>
        </div>
      </Dialog>
      <Dialog header="Zarejestruj się przez nick" v-model:visible="NoIDHandlerPrompt">
        <p>Jeśli twój ID nie posiada żadnego kodu, podaj swoje imię/nick:</p>
        <div class="textInputContainer">
          <InputText type="text" v-model="userNickname" placeholder="Imię/nick" aria-describedby="username3-help" />
          <Button @click="submitUserNickname" style="margin: 0;"><i class="pi pi-angle-right" /></Button>
        </div>
        <h4 id="username3-help" class="p-error" v-if="userNicknameSubmitTry == true">Wpisz przynajmniej jeden znak, maksymalnie 64. Akceptowane tylko litery i cyfry.</h4>
        <p>Jeśli wracasz do gry, podaj swój kod:</p>
        <div class="textInputContainer">
          <InputText type="text" v-model="userIDNickname" placeholder="Kod" aria-describedby="username3-help" />
          <Button @click="NoIDLoginViaID" style="margin: 0;"><i class="pi pi-angle-right" /></Button>
        </div>
        <h4 id="username3-help" class="p-error" v-if="userIDSubmitTry == true">Twój ID powinien mieć 5 znaków i może zawierać tylko litery i cyfry.</h4>
        <h4>{{ noIDNicknameError }}</h4>
      </Dialog>
    <div class="missingIDWarn">
      <transition name="slide-fade">
        <Message :closable="false" v-if="$store.state.userID == '' && !$store.state.changeIDPrompt && !$store.state.tutorialPrompt">
          <p>
            Aby wziąć udział w grze musisz założyć konto.
          </p>
          <Button @click="this.$store.commit('displayIDPrompt')">Uzupełnij dane</Button>
        </Message>
      </transition>
      <div class="toastContainer">
      <Toast class="toastModule" position="center" />
    </div>
    </div>
  </div>
</template>

<script>
import apiCalls from '@/services/apiCalls.service';
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import Toast from 'primevue/toast';

export default {
  data () {
    return {
      openedSidebar: false,
      userID: '',
      missingIDWarn: false,
      userIDSubmitTry: false,
      errorStatus: {},
      userNickname: '',
      userNicknameSubmitTry: false,
      NoIDHandlerPrompt: false,
      userIDNickname: '',
      noIDNicknameError: ''
    }
  },
  beforeMount() {
    this.checkWebsiteStatus()
  },
  methods: {
    sidebarGoTo(dest) {
      this.$router.push(dest)
      this.openedSidebar = false
    },
    submitUserID(){
      if (this.userID.length == 4 && /^[0-9]+$/i.test(this.userID)) {
        this.$store.commit('setUserID', this.userID)
        this.$store.commit('hideIDPrompt')
        this.$store.commit('resetLatestReward')
        this.$store.dispatch('syncStatus')
      } else
        this.userIDSubmitTry = true
    },
    submitUserNickname(){
      if (this.userNickname.length > 0 && this.userNickname.length < 64 && /^[a-z0-9]+$/i.test(this.userNickname)) {
        this.$store.commit('setNickname', this.userNickname)
        let body = { "nickname" : this.userNickname }
        body = JSON.stringify(body)
        apiCalls.post('generate-id/', body)
          .then(res => {
            this.$store.commit('setUserID', res.userid)
            this.$store.commit('hideIDPrompt')
            this.$store.commit('resetLatestReward')
            this.NoIDHandlerPrompt = false
            this.$store.dispatch('syncStatus')
            this.$toast.add({severity:'info', summary: 'Twój ID to ' + res.userid, detail:'Znajdziesz go też w zakładce mój profil. Będzie potrzebny do odebrania nagrody!', life: 8000})
          }).catch(err => {
            console.log(err)
          })
      } else
        this.userNicknameSubmitTry = true
    },
    NoIDLoginViaID(){
       if (this.userIDNickname.length == 5 && /^[a-z0-9]+$/i.test(this.userIDNickname)) {
         let body = { "userid" : this.userIDNickname }
        body = JSON.stringify(body)
         apiCalls.post('user-info/', body)
          .then(res => {
            if(res.nickname != undefined){
              this.$store.commit('setUserID', this.userIDNickname)
              this.NoIDHandlerPrompt = false
              this.$store.commit('hideIDPrompt')
              this.$store.commit('resetLatestReward')
              this.$toast.add({severity:'info', summary: 'Witaj ponownie ' + res.nickname, life: 3000})
              this.$store.dispatch('syncStatus')
            } else {
              this.noIDNicknameError = "Użytkownik o takim ID nie istnieje :("
            }
          }).catch(err => {
            this.noIDNicknameError = "Wystąpił błąd, spróbuj ponownie później."
          })
      } else
        this.userIDSubmitTry = true
    },
    howToContinue(){
      this.$store.commit('displayIDPrompt')
      this.$store.commit('hideTutorial')
    },
    howToReject(){
      this.$store.commit('hideTutorial')
      this.$router.push("/info")
    },
    checkWebsiteStatus(){
      apiCalls.get("i-hope-we-dont-have-to-use-it/")
        .then(res => this.errorStatus = res)
        .catch(err => {
          console.log(err)
          this.errorStatus = {
            "status" : 2,
            "adminMessage" : "Serwer jest nieaktywny. Spróbuj ponownie później.",
            "stopWebsite" : true
          }
        })
    }
  },
  components: {
    Sidebar,
    Button,
    Dialog,
    InputText,
    Message,
    Toast
  }
}
</script>

<style lang="scss">
#nav {
  margin: 0;
  padding: .2rem!important;
  background: var(--surface-200);
  color: white;
  display: flex;
  align-items: center;
}
h2,h4 {
  margin: 0;
}
.p-dialog {
  margin: 0 1rem;
}
.headerContainer {
  flex: auto;
}
.headerContainer a {
  text-decoration: none;
  color: inherit;
}
.textInputContainer {
  display: flex;
  align-content: space-around;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
}
#rejectButton {
  background: none;
  color: var(--milibrook-300);
}
.p-button {
  justify-content: space-around;
  margin: .5rem 0
}
.menuButtonContainer {
  flex: 1;
}
.menuButton{
  background: none!important;
}
.sidebarContainer {
  display: flex;
}
.menuItems {
  text-align: left;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.menuItems button {
  border: none;
  background: none;
  font-size: 1.5rem;
  color: var(--text-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 3px;
  cursor: pointer;
}
.bottomButtonContainer{
  margin: 1rem;
}
</style>
