<template>
  <div class="home">
    <div class="hintContainer">
      <Message>{{ codeHint }}</Message>
    </div>
    <div class="inputContainer">
      <Button @click="showCamera = !showCamera">Zeskanuj kod</Button>
      <div class="textInputContainer">
        <InputText type="text" v-model="codeInputValue" placeholder="...albo wpisz tutaj" />
        <Button @click="addCodeText"><i class="pi pi-search" /></Button>
      </div>
    </div>
    <div class="codeDisplay" v-for="(code, index) in $store.state.codesArray" :key="index">
      <Fieldset :legend="'Kod numer ' + (index + 1)" >
        <p>{{$store.state.codesArray[index]}} <i class="pi pi-check"></i></p>
      </Fieldset>
    </div>
    <div v-if="$store.state.codesArray.length > 0">
      <h4>Pozostały czas: {{ parseInt($store.state.deadlineTime - currentTime) }}</h4>
      <ProgressBar :value="parseInt($store.state.deadlineTime - currentTime)/9" :showValue="false" />
      <h3>Potrzebujesz 3 kodów, by zalosować!</h3>
      <Knob v-model="$store.state.codesArray.length" readonly :max="3" valueTemplate="{value}/3" />
      <Button v-if="$store.state.codesArray.length == 3" @click="showResults"><h3>Aktywuj!</h3></Button>
    </div>
    <div class="cameraContainer">
      <qrcode-stream v-show="showCamera" @decode="addCodeQr"></qrcode-stream>
    </div>
    <div class="toastContainer">
      <Toast class="toastModule" position="center" />
    </div>
  </div>
</template>

<script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import Toast from 'primevue/toast'
import Button from 'primevue/button'
import ProgressBar from 'primevue/progressbar';
import Fieldset from 'primevue/fieldset';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Message from 'primevue/message';
import apiCalls from '@/services/apiCalls.service';


export default {
  name: 'Home',
  data() {
    return{
      showCamera: false,
      codeInputValue: '',
      currentTime: 0,
      codeHint: '',
      expiredCodesWarning: false
    }
  },
  created() {
    this.updateTime()
    this.fetchHint()
    this.codeInputValue = this.$route.query.q
  },
  methods: {
    addCodeQr(decodedString){
      decodedString = decodedString.match(/'?q.*./)
      decodedString = decodedString.toString()
      decodedString = decodedString.replace('q=', '')
      this.submitCode(decodedString, false)
      this.showCamera = !this.showCamera
      this.fetchHint()
    },
    addCodeText(){
      this.submitCode(this.codeInputValue, false)
      this.fetchHint()
    },
    submitCode(code, genshinReward){
      this.$store.dispatch('syncStatus')
      if ( this.$store.state.deadlineTime < this.currentTime && this.$store.state.countdownActive == true ){
        this.$toast.add({severity:'error', summary: 'Twoje kody straciły ważność.', detail:'Czas twojej rezerwacji minął. Możesz spróbować dodać kody jeszcze raz, może jeszcze będą dostępne.', life: 12000})
        this.$store.commit('updateCountdownStatus', false)
      }
      let body = {
        "userid" : this.$store.state.userID,
        "code" : code,
        "genshin" : genshinReward
      }
      body = JSON.stringify(body)
      apiCalls.post("code-submit/", body)
        .then(res => {
          switch (res.status) {
            case 0:
              this.$toast.add({severity:'success', summary: 'Kod dodany!', detail:'Kod poprawny.', life: 3000})
              this.$store.commit('addCode', code)
              this.codeInputValue = ''
              this.$store.dispatch('syncStatus')
              this.$store.commit('updateCountdownStatus', true)
              break;
            case 1:
              this.$toast.add({severity:'error', summary: 'Niepoprawny użytkownik.', detail:'Coś poszło nie tak. Spróbuj ponownie za chwilę.', life: 3000})
              break;
            case 2:
              this.$toast.add({severity:'info', summary: 'Kod wygląda na niepoprawny.', detail:'Może jakaś literówka?', life: 3000})
              break;
            case 3:
              this.$toast.add({severity:'info', summary: 'Kod wykorzystany.', detail:'Ajć, trochę za późno.', life: 3000})
              break;
            case 4:
              this.$toast.add({severity:'info', summary: 'Ktoś właśnie zeskanował kod.', detail:'Może jeszcze wróci, kto wie~', life: 3000})
              break;
            case 5:
              this.$toast.add({severity:'info', summary: 'Typie, już dodałeś ten kod.', detail:'Musisz znaleźć inne.', life: 3000})
              break;
            case 6:
              this.$toast.add({severity:'success', summary: 'Wszytkie kody zebrane', detail:'Możesz teraz odebrać swoją nagrodę!', life: 3000})
              this.$store.commit('addCode', code)
              this.codeInputValue = ''
              this.theFinalCountdown()
              this.$store.commit('pushReward', res)
              this.$store.commit('updateCountdownStatus', false)
              break;
          }
        }).catch(err => {
          console.log(err)
          this.$toast.add({severity:'error', summary: 'Błąd', detail:'Coś poszło nie tak. Spróbuj ponownie za chwilę.', life: 3000})
        })
    },
    showResults(){
      this.$store.commit('clearCodes')
      this.$router.push('/result');
    },
    updateTime(){
      let self = this
      setInterval(function() {
        self.currentTime = parseInt(Date.now()/1000);
        if (self.$store.state.deadlineTime < self.currentTime && self.$store.state.countdownActive == true ) {
          self.$store.dispatch('syncStatus')
          self.$toast.add({severity:'error', summary: 'Twoje kody straciły ważność.', detail:'Czas twojej rezerwacji minął.', life: 12000})
          self.$store.commit('updateCountdownStatus', false)
        }
      }, 1000)
    },
    theFinalCountdown(){
      let k = Date.now()/1000 + 900
      this.$store.commit('updateDeadline', k)
    },
    fetchHint(){
      apiCalls.get("code-hint/")
        .then(res => {
          this.codeHint = "Podpowiedź do następnego kodu: " + res.locationHint
        }).catch(err => {
          console.log(err)
          this.codeHint = "Wystąpił błąd, spóbuj ponownie za chwilę."
        })
    }
  },
  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    Toast,
    Button,
    ProgressBar,
    Fieldset,
    InputText,
    Knob,
    Message
  }
}
</script>

<style lang="scss" scoped>
#home {
  max-width: 100%;
}
.cameraContainer {
  margin: 1rem;
}
.p-button {
  justify-content: space-around;
  margin: .5rem 0
}
.inputContainer {
  margin: 1rem .6rem;
  display: flex;
  align-content: space-around;
  flex-wrap: wrap;
  flex-direction: column;
}
.textInputContainer {
  display: flex;
  align-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
}
.textInputContainer .p-button {
  width: auto;
  font-size: 1rem;
  vertical-align: middle;
  margin: 0;
}
.codeDisplay {
  margin: 1rem;
  text-align: left;
}
.codeDisplay p {
  margin: 0;
  font-size: 1rem;
}
.toastContainer {
  margin: 1rem;
}
.toastModule {
  margin: 1rem;
  height: 100%;
}
</style>