import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/md-light-deeppurple/theme.css'
import '@/main.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css';

createApp(App)
    .use(store)
    .use(PrimeVue)
    .use(ToastService)
    .use(ConfirmationService)
    .use(router)
    .mount('#app')
