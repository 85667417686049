import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import apiCalls from '@/services/apiCalls.service.js'

const store = new createStore({
  state: {
    changeIDPrompt: false,
    tutorialPrompt: true,
    userID: '',
    nickname: '',
    codesArray: [],
    deadlineTime: 0,
    latestReward: {},
    allRewards: [],
    countdownActive: false
  },
  mutations: {
    displayIDPrompt(state) {
      state.changeIDPrompt = true
    },
    hideIDPrompt(state) {
      state.changeIDPrompt = false
    },
    setUserID(state, ID) {
      state.userID = ID
    },
    setNickname(state, nickname) {
      state.userID = nickname
    },
    syncCodes(state, code){
      state.codesArray = code
    },
    addCode(state, code){
      state.codesArray.push(code)
    },
    clearCodes(state){
      state.codesArray = []
    },
    updateDeadline(state, time){
      state.deadlineTime = time
    },
    hideTutorial(state){
      state.tutorialPrompt = false
    },
    pushReward(state, rewardData){
      state.latestReward = {
        "rarity" : rewardData.rarity,
        "rewardType": rewardData.rewardType,
        "rewardName": rewardData.rewardName,
        "rewardid": rewardData.rewardid,
        "rewardImage" : rewardData.rewardImage
      }
    },
    resetLatestReward(state){
      state.latestReward = {}
    },
    syncRewards(state, rewardData){
      state.allRewards = rewardData
    },
    updateCountdownStatus(state, status){
      state.countdownActive = status
    }
  },
  actions: {
    syncStatus({ commit, state }){
      let body = {
        "userid" : state.userID
      }
      body = JSON.stringify(body)
      apiCalls.post('user-info/', body)
        .then(res => {
          if (res.status == null){
            let deadline = new Date(res.reservationDate)
            deadline = deadline.getTime() / 1000
            commit('updateDeadline', deadline)
            commit('syncCodes', res.reservedCodes)
            commit('syncRewards', res.rewards)
          } else {
            commit('updateDeadline', '')
            commit('syncCodes', [])
            commit('syncRewards', [])
          }
          }).catch(err => {
            console.log(err)
          })
    }
  },
  modules: {
  },
  plugins: [new VuexPersistence().plugin]
})

export default store
